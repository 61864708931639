import React from 'react';
import PageWithHeader from '../components/pageWithHeader';
import style from './holiday2021.module.scss';

const Holiday2021 = () => {

  return (
    <PageWithHeader
      title="Holiday 2021"
      description="Holiday2021">
      <article className={style.mainArticle}>
        <iframe src="https://player.vimeo.com/video/659411509" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
      </article>
    </PageWithHeader>
  );
};

export default Holiday2021;
